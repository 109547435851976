<template>
  <div class="Navbar">
    <a href="#nav01" class="brand" v-smooth-scroll>
      <img src="@/assets/images/logo.png" alt="CALGJ" />
      <p>CALGJ</p>
    </a>

    <div class="Navbar__Items--right">
      <div class="Navbar__Item toggle-button" v-if="toggle">
        <svg class="toggle" v-on:click="this.toggle_menu">
          <use xlink:href="@/assets/icons/icons.svg#menu" />
        </svg>
      </div>

      <nav class="Navbar__Items" v-if="open">
        <div class="Navbar__Item Navbar__Link">
          <a href="#nav02" v-smooth-scroll>Societa</a>
        </div>
        <div class="Navbar__Item Navbar__Link">
          <a href="#nav03" v-smooth-scroll>Discipline</a>
        </div>
        <div class="Navbar__Item Navbar__Link">
          <a href="#nav04" v-smooth-scroll>Istruttori</a>
        </div>
        <div class="Navbar__Item Navbar__Link">
          <a href="#nav05" v-smooth-scroll>Social</a>
        </div>
        <div class="Navbar__Item Navbar__Link">
          <a href="#nav06" v-smooth-scroll>Palestra</a>
        </div>
        <div class="Navbar__Item Navbar__Link">
          <a href="#nav07" v-smooth-scroll>Contatti</a>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      open: true,
      toggle: false
    };
  }, // data
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("resize", this.onResize);
    });
    this.onResize();
  }, // created
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  }, // destroyed
  methods: {
    onResize(e) {
      var w = window.innerWidth;
      this.toggle = w < 1024;
      this.open = !this.toggle;
      //console.log(w)
    }, // onResize
    toggle_menu() {
      this.open = !this.open;
    } // toggle_menu
  } // methods
};
</script>

<style scoped lang="less">
@header_height: 10rem;
@button_height: calc(@header_height - 2rem);
@collapsed_menu_screen: 1024px;
@small_screen_factor: 0.5;

.Navbar {
  background-color: black;
  display: flex;
  flex: 1 1 95%;
  font-family: sans-serif;
  color: white;
  transition: all 0.3s ease-out;
  //overflow: visible;
  justify-content: space-between;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 100%;
  overflow: hidden;

  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1), 0 0.5rem 1rem rgba(0, 0, 0, 0.2);

  height: calc(@header_height - 2rem);
  z-index: 20;
  overflow: visible;

  .brand {
    //display: flex;
    //flex-direction: row;
    //overflow: hidden;
    margin-left: 5vw;
    position: relative;
    height: @header_height;

    img {
      position: absolute;
      height: @header_height;
      width: @header_height;
      top: 10px;
      left: 0;
      z-index: 12;
    }

    p {
      margin-left: calc(@header_height + 1rem);
      //padding-left: 1rem;
      font: 400 5rem / 1 "Karate", sans-serif;
      color: #fff;
      background: linear-gradient(to top, #ffea00, #f57c00);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      //align-self:center;
      //line-height: @header_height;
      margin-top: calc((@header_height - 5rem) / 2);
    }
    @media only screen and (max-width: @collapsed_menu_screen) {
      img {
        height: calc(@header_height * @small_screen_factor);
        width: calc(@header_height * @small_screen_factor);
      }
      p {
        margin-left: calc(@header_height * @small_screen_factor + 1rem);
        font: 400 3rem / 1 "Karate", sans-serif;
        //line-height: @header_height * @small_screen_factor;
        margin-top: calc((@header_height * @small_screen_factor) / 2);
      }
    }
  }
}

.Navbar__Items {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow: hidden;
  background-color: black;
  padding-left: 1em;

  @media only screen and (max-width: @collapsed_menu_screen) {
    position: absolute;
    z-index: 10;
    top: calc(@header_height * @small_screen_factor + 2rem);
    right: 1em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.Navbar__Item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, #ffea00, #f57c00);
  -webkit-background-clip: text;
  text-transform: uppercase;
  transition: all 0.3s ease-out;
  height: @button_height;
  z-index: 10;

  //background-color:green;
  width: 100%;

  @media only screen and (max-width: @collapsed_menu_screen) {
    height: calc(@header_height * @small_screen_factor);
    padding-left: 2rem;
  }
}

.Navbar__Link {
  padding-left: 2rem;
  padding-right: 2rem;
  //height:100%;

  a {
    line-height: 100%;
    z-index: 11;
    top: 0;
    left: 0;
    font: 700 1.5rem / 1 "Source Sans Pro", sans-serif;
    //background-color:blue;
  }

  &:before {
    content: "";
    position: absolute;
    height: @header_height;
    max-width: none;
    background: linear-gradient(to top, #a71d31, #3f0d12);
    transition: all 0.5s ease-out;
  }

  @media only screen and (max-width: @collapsed_menu_screen) {
    //width:30em;
    //overflow:hidden;
    padding-left: 3rem;
    padding-right: 3em;

    &:before {
      width: calc(100% + 10rem);
      transform: skewX(-10deg);
      left: calc(100% + 10rem);
      height: calc(@header_height * @small_screen_factor);
    }

    &:hover:before {
      left: 1rem;
    }
  }

  @media only screen and (min-width: @collapsed_menu_screen) {
    &:before {
      width: calc(100% + 1rem);
      transform: skewY(4deg);
      top: calc(-@header_height - 1rem);
    }

    &:hover:before {
      top: -1rem;
    }
  }
}

.Navbar__Items--right {
  margin-left: auto;
}

.Navbar__Link-toggle {
  align-self: flex-end;
  display: initial;
  position: absolute;
  cursor: pointer;
}

.Navbar__Items--right {
  margin-left: 0;
}

.toggle {
  //color: #FAB600;
  height: 3em;
  width: 3em;
  //background:grey;

  &:hover {
    background: linear-gradient(to top, #ffea00, #f57c00);
  }
}
</style>
