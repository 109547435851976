import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
//import Pay from "./views/Pay.vue";
import NotFound from "./views/not-found.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [{
      path: "/",
      name: "home",
      component: Home
    },
    // {
    //   path: "/pay",
    //   name: "pay",
    //   component: Pay
    // },
    //{
    //  path: "/contact",
    //  name: "contact",
    //  component: Home
    //},
    //{
    //  path: "/about",
    //  name: "about",
    //  // route level code-splitting
    //  // this generates a separate chunk (about.[hash].js) for this route
    //  // which is lazy-loaded when the route is visited.
    //  component: () =>
    //    import( /* webpackChunkName: "about" */ "./views/About.vue")
    //},
    { path: '/page-not-found', component: NotFound },  
    { path: '*', redirect: '/page-not-found' },  
  ]
});
