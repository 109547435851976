<template>
        <section class="blog incline-top">
            <div id="nav05" class="anchor"></div>


            <div class="blog__container container-column incline-bottom">
                <h2 >I Nostri Social Media</h2>

                    <div id="calgj"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div>

            </div>
        </section>
</template>

<script>
export default {

    mounted(){
        var i, e, d = document, s = "script";
        i = d.createElement("script");
        i.async = 1;
        i.src = "https://cdn.curator.io/published/d7681573-02ab-48b4-9342-916de25f.js";
        e = d.getElementsByTagName(s)[0];
        e.parentNode.insertBefore(i, e);
    }, // mounted
}
</script>

<style lang="less">
.blog {
    z-index: 5;
}


.crt-post-v2.crt-post.crt-post-facebook  {
    /* width: calc(100% / 4 - 1.5rem);
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    background: #fff;
    color: #000; */
    transform: skewY(-4deg) ! important;
}

.crt-post-content{
    a,.text{
        font: 250 1.7rem / 1.4 'Source Sans Pro', sans-serif!important;
    }
}

.blog:before {
    content: '';
    position: absolute;
    top: -10%;
    height: 120%;
    width: 100%;
    transform: skewY(4deg);
    background: #000 url('~@/assets/images/blog-bg01.png') 50% 50% / cover no-repeat;
}

.crt-feed {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.text.crt-post-content-text{
    font-size:10px;
    transform: skewY(4deg) ;
}

/* .blog__content_item {
    width: calc(100% / 4 - 1.5rem);
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    background: #fff;
    color: #000;
    transform: skewY(-4deg);
}

.blog__content_info {
    padding: 4rem 3rem;
    display: flex;
    flex-direction: column;
    transform: skewY(4deg);
}

.blog__content__image {
    height: 29rem;
    overflow: hidden;
}

.blog__content__image img {
    top: -10%;
    height: 120%;
    background: #a71d31;
    font: 400 2rem / 15 'Source Sans Pro', sans-serif;
    text-align: center;
    color: #fff;
    transform: skewY(4deg);
}

.blog__content_date {
    font: 800 1.6rem / 1.3 'Source Sans Pro', sans-serif;
    color: #a71d31;
}

.blog__content_h4 {
    margin-top: 1rem;
    font: 800 2.5rem / 1.3 'Source Sans Pro', sans-serif;
    text-transform: uppercase;
}

.blog__content_p {
    margin-top: 1rem;
    font: 400 1.8rem / 1.3 'Source Sans Pro', sans-serif;
}

.blog__content_social {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
}

.blog__social_item:not(:first-of-type) {
    margin-left: 1.25rem;
}

.blog__social_link {
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: .2rem solid #a71d31;
    fill: #a71d31;
    transform: skewY(-4deg);
    transition: all .3s ease-out;
}

.blog__social_link:hover {
    background: #a71d31;
    fill: #fff;
}

.blog__social_icon {
    height: 1.5rem;
    width: 1.5rem;
    transform: skewY(4deg);
} */

</style>