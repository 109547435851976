<template>
  <div id="app">
    <Navbar></Navbar>
    <router-view />

    <footer class="footer">
      <div class="footer__container container-row">
        <h6>© 2021 CALGJ - CF: 95015610108</h6>
      </div>
    </footer>
  </div>
</template>

<script>
import Navbar from "@/components/header.vue";

export default {
  name: "App",
  components: { Navbar }, // compoenents

  data() {
    return {
      dialog: false,
      clipped: false,
      drawer: true,
      fixed: false,
      items: [
        {
          icon: "fas fa-shapes",
          title: "calgj"
        }
      ],
      rightDrawer: false,

      g_checked: false,
      alert: {
        visible: false,
        text: "",
        color: "green"
      },
      contactForm: {
        name: "",
        email: "",
        message: "",
        recaptcha: "",
        remoteip: ""
      }
    };
  }, // data
  methods: {
    async handleSubmit() {
      //if (window.grecaptcha == false) return;

      this.contactForm.recaptcha = await grecaptcha.getResponse();
      //if (g_recaptcha_response.length == 0) return;
      //this.contactForm.recaptcha = g_recaptcha_response;

      console.log("captcha: " + this.contactForm.recaptcha);

      var url = "/.netlify/functions/contact";
      fetch(url, {
        method: "POST",
        body: JSON.stringify(this.contactForm)
      })
        .then(r => {
          if (r.status == 200) {
            console.log("email sent");
            this.alert.text = "Email mandato ";
            this.alert.visible = true;
          } else {
            console.log("email failed");
            this.alert.text = "ERRORE: email non mandato";
            this.alert.visible = true;
          }
        })
        .catch(e => {
          console.log("FETCH error : " + e.message);
          this.alert.text = "ERRORE: " + e.message;
          this.alert.visible = true;
        });

      this.dialog = false;
    }, // handleSubmit
    initReCaptcha: function() {
      var self = this;
      setTimeout(function() {
        if (typeof grecaptcha === "undefined") {
          self.initReCaptcha();
          return;
        }
        if (typeof grecaptcha.render != "function") {
          self.initReCaptcha();
          return;
        }
        grecaptcha.render("recaptcha", {
          sitekey: "6Ld_jYwUAAAAAE6McqucX9ee8Wa9AVPnuTY8CxXP",
          badge: "inline",
          theme: "dark",
          callback: self.enable_submit,
          "error-callbackgit": self.disable_submit,
          "expired-callback": self.disable_submit
        });
      }, 500);
    }, // initReCaptcha
    enable_submit() {
      this.g_checked = true;
      //document.getElementById('btnSubmit').disabled = false;
    }, // enable_submit
    disable_submit() {
      this.g_checked = false;
      //document.getElementById('btnSubmit').disabled = true;
    } // disable_submit
  }, // methods
  mounted() {
    //console.log(this.$route.path);

    fetch("https://api.ipify.org?format=json")
      .then(r => r.json())
      .then(d => (this.contactForm.remoteip = d.ip))
      .catch(e => console.log("ipify : " + e));

    var recaptchaStyle = document.createElement("style");
    recaptchaStyle.type = "text/css";
    var styles = `
    @media screen and (max-width: 450px){
      #rc-imageselect, .g-recaptcha {transform:scale(0.66);-webkit-transform:scale(0.60);transform-origin:0 0;-webkit-transform-origin:0 0;}
    }
    @media screen and (max-width: 300px){
      #rc-imageselect, .g-recaptcha {transform:scale(0.50);-webkit-transform:scale(0.50);transform-origin:0 0;-webkit-transform-origin:0 0;}
    }
    `;
    if (recaptchaStyle.styleSheet) recaptchaStyle.styleSheet.cssText = styles;
    else recaptchaStyle.appendChild(document.createTextNode(styles));
    document.head.appendChild(recaptchaStyle);

    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?render=explicit"
    );
    recaptchaScript.async = true;
    //recaptchaScript.defer = true
    document.head.appendChild(recaptchaScript);

    this.initReCaptcha();

    // let recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render='+this.site_key)
    // recaptchaScript.async = true
    // //recaptchaScript.defer = true
    // document.head.appendChild(recaptchaScript)

    // if(this.$route.path == '/contact'){
    //       this.toggle_contact();
    // }
  } // mounted
};
</script>

<style lang="less">
@header_height: 10rem;

@font-face {
  font-family: "Karate";
  src: url("~@/assets/fonts/Karate/Karate.woff") format("woff"),
    url("~@/assets/fonts/Karate/Karate.woff2") format("woff2"),
    url("~@/assets/fonts/Karate/Karate.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/*   My normalize   */

html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  font-size: 10px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  max-width: 100%;
}

body {
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  background: #000;
  color: #fff;
  overflow-x: hidden;
}

a {
  outline: none;
  background-color: transparent;
  text-decoration: none;
  color: inherit;
  -webkit-text-decoration-skip: objects;
}

img {
  border-style: none;
}

.container-image {
  position: relative;
  flex-shrink: 0;
}

.image-cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.image-contain {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

button,
fieldset,
iframe,
input,
optgroup,
select,
textarea,
ul {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  text-transform: none;
}

select::-ms-expand {
  display: none;
}

:invalid {
  box-shadow: none;
}

button {
  cursor: pointer;
}

button:focus,
button:active,
input:focus,
input:active,
label:focus,
label:active,
select:focus,
select:active,
textarea:focus,
textarea:active,
a:focus,
a:active {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
}

figure {
  margin: 0;
}

textarea {
  overflow: auto;
}

li {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  margin: 0;
  padding: 0;
}

::selection {
  background: #fbc500;
  color: #000;
}

::-webkit-scrollbar {
  width: 1.25rem;
  border-left: 0.125rem solid #e9e9e9;
  background-color: #eceff1;
}

::-webkit-scrollbar-thumb {
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.05), 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  background: #9e9e9e;
  transition: all 0.3s ease-out;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to top right, #a71d31, #3f0d12);
}

.container-row {
  flex-direction: row;
  flex-wrap: wrap;
}

.container-column {
  flex-direction: column;
}

.container-row,
.container-column {
  position: relative;
  z-index: 2;
  width: 144rem;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
}

.main {
  //margin-top:@header_height;
  flex: 1 0 auto;
  z-index: 0;

  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }
}

.footer {
  flex-shrink: 0;
}

/*      Content      */

.incline-top {
  transform: skewY(-4deg);
  overflow: hidden;
}

.incline-bottom {
  transform: skewY(4deg);
}

section {
  position: relative;
  z-index: 2;
  padding: 12rem 0 15rem;
}

.anchor {
  position: absolute;
  top: -12rem;
  height: 0;
  display: flex;
}

.anchor02 {
  top: 10rem;
}

.button {
  position: relative;
  height: 5rem;
  padding: 0 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: skewX(-15deg);
  overflow: hidden;
  &:disabled:before {
    background: linear-gradient(to top right, black, grey);
  }
}

.button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to top right, #a71d31, #5a0009);
}

.button:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background: linear-gradient(to top right, #5a0009, #a71d31);
  transition: all 0.3s ease-out;
}

.button:hover:after {
  opacity: 1;
}

.button__text {
  z-index: 2;
  display: flex;
  transform: skewX(15deg);
  font: 800 2rem / 1 "Source Sans Pro", sans-serif;
  color: #fff;
  text-transform: uppercase;
}

.footer {
  position: relative;
  top: -100px;
  //bottom: 0;
  left: 0;
  width: 100%;
  @media screen and (max-width: 568px) {
    //height:20em;
  }
}

.footer__container {
  height: 11rem;
  justify-content: flex-end;
  align-items: center;
}

p,
a {
  font: 300 1.5rem / 1.4 "Source Sans Pro", sans-serif;
  color: #fab600;
}

h1 {
  font: 700 4rem / 1.2 "Source Sans Pro", sans-serif;
}
h2 {
  font: 400 3rem / 1 "Karate", sans-serif;
  color: #fab600;
  text-align: center;
  background: linear-gradient(to top, #ffea00, #f57c00);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
h4 {
  font: 600 2rem / 1.1 "Source Sans Pro", sans-serif;
  text-transform: uppercase;
}
h5 {
  font: 800 1.5rem / 1 "Source Sans Pro", sans-serif;
  text-transform: uppercase;
}
h6 {
  font: 300 1.3rem / 1 "Source Sans Pro", sans-serif;
  text-transform: uppercase;
}

@media screen and (min-width: 568px) {
  p,
  a {
    font: 300 2rem / 1.4 "Source Sans Pro", sans-serif;
  }
  h1 {
    font: 700 5rem / 1.2 "Source Sans Pro", sans-serif;
  }
  h2 {
    font: 400 4rem / 1 "Karate", sans-serif;
  }
  h4 {
    font: 600 3rem / 1.1 "Source Sans Pro", sans-serif;
  }
  h5 {
    font: 800 2rem / 1 "Source Sans Pro", sans-serif;
  }
  h6 {
    font: 300 1.3rem / 1 "Source Sans Pro", sans-serif;
    text-transform: uppercase;
  }
}

@media screen and (min-width: 768px) {
  p,
  a {
    font: 300 2.5rem / 1.4 "Source Sans Pro", sans-serif;
  }
  h1 {
    font: 700 7rem / 1.2 "Source Sans Pro", sans-serif;
  }
  h2 {
    font: 400 5.5rem / 1 "Karate", sans-serif;
  }
  h4 {
    font: 600 3.5rem / 1.1 "Source Sans Pro", sans-serif;
  }
  h5 {
    font: 800 2.5rem / 1 "Source Sans Pro", sans-serif;
  }
  h6 {
    font: 300 1.5rem / 1 "Source Sans Pro", sans-serif;
    text-transform: uppercase;
  }
}

@media screen and (min-width: 1200px) {
  h1 {
    font: 700 9rem / 1.2 "Source Sans Pro", sans-serif;
  }

  h2 {
    font: 400 7rem / 1 "Karate", sans-serif;
  }

  h4 {
    font: 600 4rem / 1.1 "Source Sans Pro", sans-serif;
  }

  h5 {
    font: 800 3rem / 1 "Source Sans Pro", sans-serif;
  }
  h6 {
    font: 300 1.5rem / 1 "Source Sans Pro", sans-serif;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 768px) {
  .home {
    height: 50vh;
  }
}

@media screen and (max-width: 480px) {
  .home {
    height: 40vh;
  }
}

@SM: 568px;
@MD: 768px;
@LG: 992px;
@XL: 1200px;

.xs-12 {
  flex: 1 1 100%;
  width: 100%;
}
.xs-6 {
  flex: 1 1 50%;
  width: 50%;
}
.xs-4 {
  flex: 1 1 33%;
  width: 33%;
}

@media screen and (min-width: @SM) {
  .sm-12 {
    flex-basis: 100%;
  }
  .sm-6 {
    flex-basis: 50%;
  }
  .sm-4 {
    flex-basis: 33%;
  }
}

@media screen and (min-width: @MD) {
  .md-12 {
    flex: 1 1 100%;
  }
  .md-6 {
    flex: 1 1 50%;
  }
  .md-4 {
    flex: 1 1 33%;
  }
}

@media screen and (min-width: @LG) {
  .lg-12 {
    flex-basis: 100%;
  }
  .lg-6 {
    flex-basis: 50%;
  }
  .lg-4 {
    flex-basis: 33%;
  }
}

// TYPOGRAPHY
// body,
// p {
//     font-size: 20px;
//     line-height: 1.5;
// }

// h6 {
//     text-transform: uppercase;
//     color: grey !important;
//     letter-spacing: 3px !important;
// }

// @media (max-width:@MD) {
//     html {
//         font-size: 14px !important;
//     }

//     a h1 {
//         font-size: 2rem !important;
//     }
// }
</style>
