<template>
        <section class="contact incline-top">
            <div id="nav07" class="anchor"></div>

            <div class="container-column incline-bottom">
                <h2>Contattaci</h2>

                <div class="contact__container">
                    <div class="col2">
                        
                        <form class="contact__form" name="contact" v-on:submit.prevent="handleSubmit">
                            <div class="contact__form_item">
                                <input type="text" class="contact__form_input" placeholder="Il tuo NOME" v-model="contactForm.name" required>
                                <div class="contact__form_bg"></div>
                            </div>
                            
                            <div class="contact__form_item">
                                <input type="text" class="contact__form_input" placeholder="Il tuo EMAIL" v-model="contactForm.email" required>
                                <div class="contact__form_bg"></div>
                            </div>
                            
                            <div class="contact__form_item">
                                <textarea name="contact__form_message" id="contact__form_message" cols="30" rows="10" 
                                    class="contact__form_textarea contact__form_input" placeholder="Il tuo MESSAGGIO" 
                                    v-model="contactForm.message" required></textarea>
                                <div class="contact__form_bg"></div>
                            </div>

                            <div id="recaptcha" class="g-recaptcha" style="margin-top:20px;"></div>

                            <button class="contact__form_button button" @click="handleSubmit" :disabled="!g_checked">
                                <span class="button__text">Invia</span>
                            </button>

                            <div id="alert" v-bind:class="{ success: alert.error == false, error: alert.error }">
                                <span class="closebtn" @click="alert_hide">&times;</span> 
                                {{alert.text}}
                            </div>
                        
                        </form>
                
                    </div>

                    <div class="col2">
                    
                        <div class="contact__info_item 6">
                            <h4 class="contact__info_h4">Indirizzo:</h4>
                            <a href="https://www.google.com/maps/dir//asd+CALGJ+-+Club+Atletico+Lotta+Giapponese+Ju+Jitsu,+Piazzale+S.+Benigno,+1,+16149+Genova+GE,+Italy/@44.4069961,8.9043941,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x12d341eb7be64e75:0xf8e88c8498bc0185!2m2!1d8.9065881!2d44.4069923!3e0" 
                            class="contact__info_a" target="_blank" rel="noopener">Piazzale San Benigno, 1 - Genova</a>
                        </div>
                        <div class="contact__info_item">
                            <h4 class="contact__info_h4">Telefono:</h4>
                            <a href="tel:+393496056088" class="contact__info_a" target="_blank" rel="noopener">Carlo : 349 6056 088</a><br>
                            <a href="tel:+393470021732" class="contact__info_a" target="_blank" rel="noopener">Alessio : 347 002 1732</a>
                        </div>
                        <div class="contact__info_item">
                            <h4 class="contact__info_h4">Email:</h4>
                            <a href="mailto:info@calgj.it" class="contact__info_a" target="_blank" rel="noopener">info@calgj.it</a>
                        </div>
                    </div>

                </div>
            </div> 
            </div>
        </section>

</template>

<script>
export default{
    data () {
        return {
            g_checked:false,
            alert:{
                //visible:false,
                text:'',
                error:false
            },
            contactForm: {
                name: '',
                email: '',
                message: '',
                recaptcha: '',
                remoteip: ''
            }
        } // rerturn
    }, // data
    methods: {
      alert_hide(){
        this.alert.text="";
        this.alert.error=false;        
        document.getElementById('alert').style.opacity = "0";
        //this.alert.visible = false;
      },
      show_alert(text, error){
          console.log(text);
            this.alert.text=text;
            this.alert.error=error;
            //this.alert.visible = true;
            document.getElementById('alert').style.opacity = "1";
            setTimeout(this.alert_hide, 5500);
      },
      async handleSubmit(){
        //if (window.grecaptcha == false) return;
        this.contactForm.recaptcha = await grecaptcha.getResponse();
        //if (g_recaptcha_response.length == 0) return;
        //this.contactForm.recaptcha = g_recaptcha_response;
        //console.log('contactform: ' + JSON.stringify(this.contactForm));
        //this.show_alert(JSON.stringify(this.contactForm));

            var url = '/.netlify/functions/contact'
            fetch(url,{
              method: "POST",
              body: JSON.stringify(this.contactForm)
            }).then(r => {
              console.log(JSON.stringify(r));
              if(r.status==200) {
                  this.show_alert("Email mandato", false)
                //console.log('email sent');
                // this.alert.text="Email mandato ";
                // this.alert.visible = true;
              }else{
                  this.show_alert("ERRORE: " + JSON.stringify(r), true);
                //console.log('email failed');
                // this.alert.text="ERRORE: email non mandato";
                // this.alert.visible = true;
              } 
            }).catch(e => { 
                  this.show_alert("ERRORE: " + e.message, true);
                // console.log('FETCH error : ' + e.message)
                // this.alert.text="ERRORE: " + e.message;
                // this.alert.visible = true;
            });
      }, // handleSubmit
      initReCaptcha: function() {
        var self = this;
        setTimeout(function() {
            if(typeof grecaptcha === 'undefined') {
                self.initReCaptcha();
                return;
            }
            if (typeof grecaptcha.render != 'function') { 
                self.initReCaptcha();
                return;
            }
            grecaptcha.render('recaptcha', {
                sitekey: '6Ld_jYwUAAAAAE6McqucX9ee8Wa9AVPnuTY8CxXP',
                badge: 'inline',
                theme: 'dark',
                callback: self.enable_submit,
                'error-callbackgit': self.disable_submit,
                'expired-callback': self.disable_submit
            });
        }, 500);
    }, // initReCaptcha
      enable_submit(){
        this.g_checked = true;
      }, // enable_submit
      disable_submit(){
        this.g_checked = false;
      }, // disable_submit
  }, // methods
  mounted(){
    //console.log(this.$route.path);
     
    fetch('https://api.ipify.org?format=json')
      .then(r => r.json())
      .then(d => this.contactForm.remoteip = d.ip)
      .catch(e => console.log('ipify : ' + e));
    var recaptchaStyle = document.createElement('style')
    recaptchaStyle.type = 'text/css';
    var styles = `
    @media screen and (max-width: 450px){
      #rc-imageselect, .g-recaptcha {transform:scale(0.66);-webkit-transform:scale(0.60);transform-origin:0 0;-webkit-transform-origin:0 0;}
    }
    @media screen and (max-width: 300px){
      #rc-imageselect, .g-recaptcha {transform:scale(0.50);-webkit-transform:scale(0.50);transform-origin:0 0;-webkit-transform-origin:0 0;}
    }
    `;
    if (recaptchaStyle.styleSheet) recaptchaStyle.styleSheet.cssText = styles;
    else recaptchaStyle.appendChild(document.createTextNode(styles));
    document.head.appendChild(recaptchaStyle)
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=explicit')
    recaptchaScript.async = true
    //recaptchaScript.defer = true
    document.head.appendChild(recaptchaScript)
    this.initReCaptcha();
    
    // let recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render='+this.site_key)
    // recaptchaScript.async = true
    // //recaptchaScript.defer = true
    // document.head.appendChild(recaptchaScript)
  }, // mounted
}
</script>

<style lang="less">

    // .col2{
    //     flex: 0 1 calc(50% - 2em);
    //     padding: 2em;
    //     @media screen and (max-width : 768px) {
    //         flex: 0 1 calc(100% - 2em);
    //         margin-top:3em;
    //     }
    // }

    #alert {
        padding: 20px;
        color: black;
        margin-top: 15px;
        margin-bottom: 15px;  
        opacity: 0;
        transition: opacity 0.8s; /* 600ms to fade out */
        font-size:150%;
        overflow:hidden;
        text-align:center;
        flex:1 1 100%;
        max-width:100%;
        &.error{
            background-color: #F2DEDE; 
        }
        &.success{
            background-color: #DFF0D8; 
        }
    }

    .closebtn {
        margin-left: 15px;
        color: white;
        font-weight: bold;
        float: right;
        font-size: 22px;
        line-height: 20px;
        cursor: pointer;
        transition: 0.3s;
        
        &:hover {
            color: black;
        }
    }


    .contact {
        z-index: 3;
        display:flex;

        &:before {
            content: '';
            position: absolute;
            top: -10%;
            height: 120%;
            width: 100%;
            background: #3e3e3e url('/src/assets/images/contact-bg01.png') 75% 0% / cover no-repeat;
        }
    }

    .contact__container {
        margin-top: 7rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        > div{
            flex: 1 1 calc(50%);
            padding: 2em;
            @media screen and (max-width : 768px) {
                flex: 1 1 100%;
                //margin-top:3em;
            }
        }
    }

    .contact__form {
        //padding-left: 2.5rem;
        display: flex;
        flex-direction: column;

        flex:1 0 100%;
        max-width:100%;
    }

    .contact__form_item {
        display: flex;
        border: .3rem solid #7e0214;
        transform: skewX(-15deg);

        flex:1 1 100%;
        max-width:100%;
    }

    .contact__form_item:last-of-type {
        transform: skewX(-15deg);
    }

    .contact__form_input {
        height: 5rem;
        width: 100%;
        padding: 0 5rem ;
        font: 400 2rem / 1 'Source Sans Pro', sans-serif;
        transform: skewX(15deg);
        transition: all .3s ease-out;
    }

    .contact__form_input:focus + .contact__form_bg {
        background: #fff;
    }

    .contact__form_bg {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(255,255,255,.75);
        transition: all .3s ease-out;
    }

    .contact__form_input::-webkit-input-placeholder {
        color: #000;
    }

    .contact__form_input::-moz-placeholder {
        color: #000;
        opacity: 1;
    }

    .contact__form_input:-ms-input-placeholder {
        color: #000;
    }

    .contact__form_item:not(:first-of-type) {
        margin-top: 2rem;
    }

    .contact__form_textarea {
        height: 15rem;
        margin-top: 2rem;
        resize: none;
    }

    .contact__form_button {
        margin-top: 2rem;
    }

    .contact__form_button .button__text {
        font: 800 2rem / 1 'Source Sans Pro', sans-serif;
    }

    .contact__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .contact__info_item:not(:first-of-type) {
        margin-top: 5rem;
    }

    .contact__info_h4 {
        margin-bottom: 2rem;
        //font: 600 4.5rem / 1 'Source Sans Pro', sans-serif;
        //text-transform: uppercase;
        color: rgba(255,255,255,.5);
    }

    .contact__info_a {
        margin-top: 2rem;
        //font: 400 4.5rem / 1 'Source Sans Pro', sans-serif;
        transition: all .3s ease-out;
    
        &:hover {
            color: #a71d31;
        }
    }

</style>