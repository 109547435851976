<template>

    <main class="main">
        <section class="home">
            <div id="nav01" class="anchor"></div>
            <div class="home__container container-column">
                <h1>Club Atletico <br>Lotta Giapponese<br>Ju Jitsu</h1>
                <a href="#nav07" class="home__button button" v-smooth-scroll>
                    <span class="button__text">Contattaci</span>
                </a>
            </div>
        </section>

        <Advantages></Advantages>
        <About></About>
        <Discipline></Discipline>
        <Instructors></Instructors>
        <Blog></Blog>
        <Location></Location>
        <Contact> </Contact>
        

        <ul class="social incline-top">
            <li class="social__item">
                <a href="https://www.facebook.com/calgj.jujitsu/" class="social__item_link" target="_blank" rel="noopener">
                    <svg class="social__item_icon">
                        <use xlink:href="@/assets/icons/icons.svg#facebook"/>
                    </svg>
                </a>
            </li>
            <li class="social__item">
                <a href="https://www.instagram.com/calgj.jujitsu/" class="social__item_link" target="_blank" rel="noopener">
                    <svg class="social__item_icon">
                        <use xlink:href="@/assets/icons/icons.svg#instagram"/>
                    </svg>
                </a>
            </li>
        </ul>
    </main>


</template>

<script>
  import Advantages from '../components/advantages.vue'
  import About from '../components/about.vue'
  import Location from '../components/locations.vue'
  import Discipline from '../components/discipline.vue'
  import Instructors from '../components/instructors.vue'
  import Contact from '../components/contact.vue'
  import Blog from '../components/blog.vue'

  export default {
    name:'Home',
    components:{ 
      Advantages,About,Instructors,Discipline,Location,Contact,Blog
    }, // compoenents
    // created:function(){
    //   var i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;
    //   i.src = "/curator/published/d7681573-02ab-48b4-9342-916de25f.js";
    //   e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
    // }, // created
  }
</script>

<style lang="less">
\

.home {
    height: 100vh;
    padding: 0;
    display: flex;
    z-index:3;
}

.home:before {
    content: '';
    position: absolute;
    z-index:1;
    height: calc(100% + 34rem);
    width: 100%;
    top: 0;
    left: 0;
    padding: 0;
    display: flex;
    background: #000 url('~@/assets/images/home__bg01.png') 50% 35% / cover no-repeat;
    @media screen and (max-width : 768px) {
      background: #000 url('~@/assets/images/home__bg01.png') 60% 35% / cover no-repeat;
    }
    @media screen and (max-width : 568px) {
      background: #000 url('~@/assets/images/home__bg01.png') 65% 35% / cover no-repeat;
    }
    @media screen and (max-width : 360px) {
      background: #000 url('~@/assets/images/home__bg01.png') 70% 35% / cover no-repeat;
    }

}

.home__container {
    padding: 13rem 2rem 2rem;
    justify-content: center;
    align-items: flex-start;
}

.home__button {
    margin-top: 5rem;
    margin-left: 1rem;
}
 
.social {
    display: flex;
    position: relative;
    top: -2.25rem;
    z-index: 5;
    justify-content: center;
}

.social__item {
    position: relative;
}

.social__item:not(:first-of-type) {
    margin-left: 1.25rem;
}

.social__item:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.social__item:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all .3s ease-out;
}

.social__item_link {
    height: 4.5rem;
    width: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: skewY(4deg);
    overflow: hidden;
}

.social__item:hover:after {
    opacity: 1;
}

.social__item:nth-of-type(1):before {
    background: linear-gradient(to top right, #04619F, #130F40);
}

.social__item:nth-of-type(1):after {
    background: linear-gradient(to top right, #130F40, #04619F);
}

.social__item:nth-of-type(2):before {
    background: linear-gradient(to top right, #833ab4, #fd1d1d, #fcb045);
}

.social__item:nth-of-type(2):after {
    background: linear-gradient(to top right, #fcb045, #fd1d1d, #833ab4);
}

.social__item:nth-of-type(3):before {
    background: linear-gradient(to top right, #009FFD, #2A2A72);
}

.social__item:nth-of-type(3):after {
    background: linear-gradient(to top right, #2A2A72, #009FFD);
}

.social__item_icon {
    height: 2.4rem;
    width: 2.4rem;
    fill: #fff;
}

</style>
